import recallServices from '../../../services/notisphere/recall'
import recallSupplierServices from '../../../services/supplier/supplier-recall'
import recallProviderServices from '../../../services/provider/recall'
import RecallDetails from './RecallDetails'
import ChangeHistory from './ChangeHistory'
import WorkflowActions from '../../share/WorkflowActions'
import { COMMUNICATION_TYPE, USER_TYPE } from '../../../misc/commons'

export default {
    props: {
        id: { type: String, default: null },
    },
    components: {
        RecallDetails,
        ChangeHistory,
        WorkflowActions,
    },
    data() {
        return {
            item: null,
            communicationType: null,
            disabledDelegate: false,
            providerCommunicationId: null,
            completedRecall: null,
            tabDetails: true,
            tabHistory: false,
            tabWorkflow: false,
        }
    },
    created() {
        this.communicationType = COMMUNICATION_TYPE.RECALL
        this.getRecallById()
        if (localStorage.getItem('typeFilterVal') == USER_TYPE.SUPP) {
            this.getSupplierCommunicationSummaryById()
        } else if (localStorage.getItem('typeFilterVal') == USER_TYPE.PROV) {
            this.getProviderCommunicationIdById()
        }
    },
    methods: {
        getRecallById() {
            recallServices.getRecallById(this.id).then((resp) => {
                if (resp.error) {
                    this.$router.push({ name: 'noti_Recall' })
                } else {
                    this.item = resp.data.d
                }
            })
        },
        deleteRecall() {
            this._showConfirm(
                'Are you sure you want to delete the recall, this cannot be undone?',
                { title: 'Delete Recall', variant: 'danger' }
            ).then((confirm) => {
                if (confirm) {
                    recallServices
                        .deleteRecall(this.id, this.item.supplier_id)
                        .then((resp) => {
                            if (!resp.error) {
                                this.$router.push({ name: 'noti_Recall' })
                                this._showToast('Recall Deleted!')
                            }
                        })
                }
            })
        },
        onReLoadCommunicationData() {
            if(localStorage.getItem('typeFilterVal') == USER_TYPE.SUPP) {
                this.getSupplierCommunicationSummaryById()
            } else if (localStorage.getItem('typeFilterVal') == USER_TYPE.PROV) {
                this.getProviderCommunicationIdById()
            }
        },
        getSupplierCommunicationSummaryById() {
            recallSupplierServices
                .getCommunicationSummaryById(this.id)
                .then((resp) => {
                    if (resp.error) {
                        this.$router.push({ name: 'noti_Recall' })
                    } else {
                        if (this.item.status == 'CLOSED') {
                            this.disabledDelegate = true
                            this.completedRecall = true
                        } else {
                            this.disabledDelegate = false
                        }
                    }
                })
        },
        getProviderCommunicationSummaryById() {
            recallServices.getRecallSummaryById(this.id,this.providerCommunicationId.toString()).then(resp => {
                if (resp.error){
                    this.$router.push({ name: 'noti_Recall' })
                } else {
                    this.item = resp.data.d
                    if(this.item.completed_at != null)
                    {
                        this.completedRecall=true
                    }
                    if(this.item.providerEventStatus == 'DRAFT' || this.item.providerEventStatus == 'COMPLETED' || this.item.providerEventStatus == 'CLOSED' || this.item.completed_at != null)
                    {
                        this.disabledDelegate=true
                    }
                    else
                    {
                        this.disabledDelegate=false
                        this.completedRecall=false
                    }
                }
            })
        },
        getProviderCommunicationIdById() {
            recallProviderServices
                .getProviderCommunicationId(this.id)
                .then((resp) => {
                    if (resp.error) {
                        this.$router.push({ name: 'noti_Recall' })
                    } else {
                        this.providerCommunicationId = resp.data.d
                        this.getProviderCommunicationSummaryById()
                    }
                })
        },
    },
}
